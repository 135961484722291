<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        headers: [
          { text: 'Code', value: 'code' },
          { text: 'Campaign', value: 'campaign' },
          {
            text: 'Assigned to',
            value: 'userId',
            filter: true,
            component: { name: 'dtView', props: { name: 'user', permission: 'user' } },
          },
          { text: 'Total redemptions', value: 'totalRedemptions', align: 'center' },
        ],
        hideAction: true,
      },
    };
  },
};
</script>
